.typoname
{
    font-size : 25px !important;
}

@media(max-width : 1300px)
{
    .typoname
    {
        font-size : 20px !important;
    }
}